.factory {
  display: flex;
  flex-direction column
  gap 16px
  margin-bottom 24px
  &.splide {
    +above(769px) {
      display none
    }
    padding-bottom 52px
    margin-bottom 8px
    .splide__pagination {
      bottom 18px
    }
  }
  &:not(.splide) {
    +below(768px) {
      display none
    }
  }
  &__item {
    display: grid;
    min-height 182px;
    max-height max-content;
    border-radius 16px
    overflow hidden
    background: cardbg;
    grid-template-columns 1fr 1.135fr
    +below(768px) {
        display flex
        flex-direction column
        height auto
    }
    &_add {
      grid-template-columns 1.135fr 1fr
      & .factory__item_content {
        padding-left 64px
        order 1
      }
    }
    &_img {
      width 100%
      height 100%
      object-fit cover
      &-container {
        height 100%
        flex 1
        +below(768px) {
          width 100%
          height 182px
          min-height 182px
        }
      }
    }
    &_content {
      padding 32px 64px 32px 32px
      display flex
      flex-direction column
      gap 12px
      +below(768px) {
        padding 32px 32px 32px 32px
        width 100%
        gap 8px
      }
    }
  }
}

.process {
  width 100%
  max-width 100%
  &__item {
    display flex
    flex-direction column
    width: 544px
    height 482px
    border-radius 16px
    overflow hidden
    background: cardbg;
    +below(768px) {
      width 100%
      height auto
    }
    &_img {
      width 100%
      height 100%
      object-fit cover
      &-container {
        width 100%
        height 280px
        +below(768px) {
          height 182px
        }
      }
    }
    &_content {
      padding 32px 64px 32px 32px
      display flex
      flex-direction column
      gap 12px
    }
  }
  &_splide {
    overflow visible
    padding-bottom 52px
    margin-bottom 8px
    .splide__pagination {
      display flex !important
      bottom 18px
    }
    & .splide__arrow {
      +below(768px) {
        display none
      }
      &--prev {
        left -22px
      }
      &--next {
        right -22px
      }
    }
  }
}
