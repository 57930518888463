.payment {
  display grid
  grid-template-columns repeat(2, 1fr)
  width 100%
  justify-content space-between
  +below(1100px) {
    grid-template-columns repeat(1, 1fr)
  }
  +below(768px) {
    grid-template-columns repeat(2, 1fr)
  }
  +below(481px) {
    grid-template-columns repeat(1, 1fr)
  }
  gap 16px
  &__item {
    border-radius: 16px;
    min-height: 234px;
    padding: 32px;
    background-color: grey;
    background-repeat no-repeat
    background-size cover
    overflow hidden;
    transition all 0.3s
    cursor pointer;
    background-position: right;
    +below(768px) {
      width 100%
      padding: 12px 12px 12px 32px;
      min-height: 160px;
    }
    &_text {
      +below(768px) {
        display none
      }
    }
    &_content {
      display flex
      flex-direction column
      gap 12px
      max-width 240px
      +below(768px) {
        max-width 100%
        flex-direction row
        justify-content space-between
        align-items: center;
      }

    }
    &:hover {
      box-shadow: -1px 5px 12px 0 rgba(0, 0, 0, 0.1), -5px 22px 22px 0 rgba(0, 0, 0, 0.09), -11px 49px 30px 0 rgba(0, 0, 0, 0.05), -19px 87px 36px 0 rgba(0, 0, 0, 0.01), -30px 137px 39px 0 rgba(0, 0, 0, 0);
      & .payment__item_circle {
        transform: translateX(10%);
      }
    }
    &_instalment {
      background-image url("../images/payment_bg-01.png")
      +below(768px) {
        background-image url("../images/payment_bg-01-mob.png")
      }
    }
    &_acquiring {
      background-image url("../images/payment_bg-02.png")
      +below(768px) {
        background-image url("../images/payment_bg-02-mob.png")
      }
    }
    &_circle {
      border: 1px solid darkred;
      border-radius: 100px;
      padding: 8px;
      width: 40px;
      height: 40px;
      display flex;
      align-items: center;
      justify-content: center;
      background-color: white;
      transition all 0.3s;
    }
  }
}

.payment-page {
  display flex
  flex-direction column
  gap 16px
  +below(768px) {
    margin-top 16px
  }
  &__banner {
    min-width: 100%;
    min-height: 284px;
    border-radius 16px
    overflow hidden
    background-size cover
    background-repeat no-repeat
    background-position center
    padding 56px 64px
    +below(768px) {
      background-position: bottom -90px center;
      min-height: 480px;
      padding 32px 42px 32px 32px
    }
    +below(550px) {
      background-position bottom
    }
    &_instalment {
      background-image url("../images/instalmet.png")
      +below(768px) {
        background-image url("../images/instalmet-mob.png")
      }
      +between(769px, 825px) {
        background-position center right -350px
      }
    }
    &_acquiring {
      background-image url("../images/acquiring.png")
      +below(768px) {
        background-image url("../images/acquiring-mob.png")
      }
    }
    &_content {
      display flex
      flex-direction column
      gap 16px
      max-width 347px
      +below(768px) {
        max-width 100%
      }
    }
  }
  &__catalog {
    margin-top 40px
    margin-bottom 12px
    +below(768px) {
      margin-top 32px
      margin-bottom 0
    }
  }
}
