.product {
  width 100%
  max-width: 100%;
  position relative
  +below(768px) {
    margin-bottom 160px
  }

  &__info {
    position absolute
    bottom 40px
    right 264px
    z-index 1
    border-radius: 16px;
    padding: 24px 23px;
    width: 226px;
    background: white;
    border-bottom: 1px solid divider;
    border-left: 1px solid divider;
    border-right: 1px solid divider;
    display flex
    flex-direction column
    gap 16px

    &_price {
      display flex
      flex-direction column
      gap 8px

      &_old {
        display flex
        align-items center
        gap 8px
      }

      &_sale {
        border-radius: 4px;
        padding: 0 8px;
        background red
        display flex
        align-items center
        justify-content center
      }
    }
    +below(1024px) {
      right 40px
    }
    +below(768px) {
      top 100%
      bottom auto
      margin-top -24px
      left 0
      right 0
      width auto
    }
    &_mobile {
      position fixed
      top 0
      left 0
      background white
      z-index 1100
      width 100%
      padding 16px 20px
      border-bottom 1px solid #D7D7D7
      display flex
      gap 24px
      +above(769px) {
        display none
      }
      transform translateY(-50%) scale(1.1)
      opacity 0
      transition all .3s
      visibility hidden
      &.active {
        transform translateY(0)
        opacity 1
        visibility visible
      }
      &_left {
        flex 1
      }
      .text-h2 {
        margin-bottom 16px
        padding-right 56px
      }
      .product__info_price {
        margin-bottom 4px
        flex-direction row
      }
      .button-order {
        padding 8px
        position: absolute;
        right 20px
        top 16px
      }
    }
  }

  &__sliders {
    width 100%
    display flex
    gap 16px
    height 733px
    +below(768px) {
      margin-left -20px
      margin-right -20px
      height 320px
      width auto
    }
  }

  &__main {
    width 1100px
    border-radius 16px
    overflow hidden
    max-width 100%
    +below(768px) {
      border-radius 0
      .splide__pagination {
        bottom 46px
      }
    }

    & .splide {
      &__track {
        width 100%
        height 100%

        & img {
          min-height 100%
          min-width 100%
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    +below(768px) {
      .splide__arrows {
        display none
      }
    }
  }

  &__thumbnail {
    min-width: 212px;
    width: 212px;
    padding-right: 34px;
    max-height: 733px;
    height: 733px;
    overflow: auto;
    +below(1024px) {
      display none
    }

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: cardbg;
      height: 4px;
      border-radius: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkred;
      height: 4px;
      border-radius: 4px;
      width: 4px;
    }

    & .splide {
      &__slide {
        border-radius: 16px;
        width: 174px;
        height: 115px;
        overflow: hidden;

        &.is-active {
          border: 2px solid darkred !important;
          background: darkred;

          & img {
            opacity 0.6
          }
        }

        & img {
          height 100%
          width 100%
          object-fit cover
        }
      }
    }
  }
}

.included {
  border: 1px solid divider;
  border-radius: 16px;
  padding: 32px 64px 32px 32px;
  overflow: hidden;
  display: flex;
  align-items center
  width: 100%;
  justify-content space-between;
  +below(1200px) {
    flex-direction column
    gap 24px
    align-items flex-start
  }
  +below(768px) {
    border 0
    padding 0
    overflow visible
    margin-bottom 8px
    .text-h3 {
      font-size 20px
      line-height 26px
    }
  }

  &__items {
    display: flex;
    align-items: center;
    gap 40px
    +below(1200px) {
      display grid
      width 100%
      grid-template-columns repeat(4, 1fr)
    }
    +below(1024px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(768px) {
      gap 16px

      grid-template-columns repeat(2, 1fr)
    }
    +below(480px) {
      grid-template-columns 1fr
    }

    &__item {
      display: flex;
      align-items: center;
      gap 8px
      max-width 200px
      +below(768px) {
        max-width 100%
      }

      &_img {
        max-width 40px
        max-height 40px
      }
    }
  }
}

.product-stocks {
  width: 100%;
  max-width: 100%;
  margin-bottom 24px

  & .splide {
    &__slide {
      width: 540px;
      height: 240px;
      +below(768px) {
        width 264px
        height auto
        --stock-size: 152px
      }
    }
  }

  & .stocks__item_content {
    max-width 243px
  }
}


.configurator {
  margin-bottom 24px
  display flex
  justify-content space-between
  width 100%
  +below(768px) {
    flex-direction column
    gap 24px
  }

  &__content {
    display flex
    flex-direction column
    gap 32px
    max-width 372px

    &_block {
      display flex
      flex-direction column
      gap 16px

      &_items {
        display flex
        gap 8px
        flex-wrap wrap
      }
    }

    &_form {
      border: 1px solid text22;
      background: white;
      border-radius: 8px;
      width: 112px;
      height: 80px;
      display: flex;
      flex-direction column
      justify-content space-between
      align-items: center;
      padding: 17px 8px 12px 8px;
      transition all .3s
      cursor pointer

      & svg {
        transition all .3s
      }

      &:hover {
        background: buttonhover;
      }

      &:active {
        background: buttonactive;
        border: 1px solid darkred;
        color: darkred;

        & svg {
          stroke: darkred;
        }
      }

      &-active {
        background: buttonactive;
        border: 1px solid darkred;
        color: darkred;

        & svg {
          stroke: darkred;
        }
      }
    }

    &_circle {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      overflow: hidden;
      transition all .3s
      cursor pointer
      border: 2px solid transparent;

      &:hover {
        transform: scale(1.1);
      }

      &-active {
        border: 2px solid darkred !important;
      }

      &-add {
        border: 2px solid divider;
      }

      &_img {
        transform: translateX(-2px) translateY(-2px);
        width: calc(100% + 4px);
        height calc(100% + 4px);
        object-fit cover;
      }
    }
  }

  &__img {
    width 100%
    height 100%
    object-fit cover
    position absolute
    top 0
    left 0
    transition all .3s ease-in
    &-active {
      opacity 1
    }
    &-disabled {
      opacity 0
    }

    &-container {
      width 880px
      height 608px
      border-radius: 16px;
      overflow hidden
      position relative
      +below(768px) {
        width 100%
        height 228px
      }
    }
  }
}

.quality {
  margin-bottom 24px
  display flex
  gap 16px
  min-height 408px
  width 100%
  +below(1100px) {
    flex-direction column
    gap 24px
  }

  &__items {
    display flex
    flex-direction column
    gap 16px
    min-width 320px
    width 320px
    +below(1100px) {
      width 100%
      display grid
      grid-template-columns repeat(4, 1fr)
      min-width auto
    }
    +below(1024px) {
      grid-template-columns repeat(3, 1fr)
    }
    +below(768px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(480px) {
      grid-template-columns 1fr
    }
  }

  &__item {
    width 100%
    border-radius: 16px;
    height: 90px;
    background: cardbg;
    position relative
    overflow hidden
    padding 32px
    display flex
    align-items center
    cursor: pointer;
    transition all .3s
    +below(768px) {
      height 60px
      padding 19px 32px
    }

    &:hover {
      transform scale(1.05)
    }

    &_img {
      width 100%
      height 100%
      object-fit cover
      +below(768px) {
        display none
      }
      &_mobile {
        width 100%
        height 100%
        object-fit cover
        display none
        +below(768px) {
          display block
        }
      }

      &-container {
        position absolute
        right 0
        top 0
        width 320px
        height 100%
        z-index 0
        max-width 100%
        object-fit cover
        +below(768px) {
          width 100%
          height 60px
        }
      }
    }

    & div {
      z-index 1
    }
  }

  &__content {
    border-radius: 16px;
    overflow hidden
    max-width 100%
    width: 100%;
    max-height 100%
    position relative

    &_item {
      border-radius: 16px;
      overflow hidden
      position absolute
      top 0
      left 0
      background: cardbg;
      max-width 100%
      width: 100%;
      height 100%
      display flex
      min-height: 408px;
      transition all .5s ease-out
      transform translateY(-100%)
      opacity 0
      +below(1100px) {
        min-height: auto;
        &.quality__content_item_active {
          position relative
          flex-direction column
        }
      }

      &_active {
        transform translateY(0)
        opacity 1
      }

      &_img {
        width 100%
        height 100%
        object-fit cover
        +below(768px) {
          display none
        }
        &_mobile {
          width 100%
          height 100%
          object-fit cover
          display none
          +below(768px) {
            display block
          }
        }

        &-container {
          height 100%
          max-height 100%
          overflow hidden
          +below(1100px) {
            height 240px
            width 100%
          }
        }
      }

      &_info {
        padding 64px
        display flex
        flex-direction column
        gap 16px
        justify-content: center;
        width 406px
        min-width 406px
        +below(1100px) {
          padding 32px
          padding-top 24px
          width 100%
          min-width 100%
        }
      }
    }
  }
}

.sale {
  width 100%
  display flex
  flex-direction column
  text-align: center;
  gap 24px

  &__content {
    gap 16px
    text-align: center;
    grid-template-columns repeat(4, 1fr)
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }
    +above(769px) {
      display grid
    }
    +below(768px) {
      display grid
      flex-direction column
      grid-template-columns 1fr
      padding-bottom 44px
    }

    &.splide {
      +above(769px) {
        display none
      }
      .splide__pagination {
        bottom 16px
      }
    }

    &:not(.splide) {
      +below(768px) {
        display none
      }
    }

    &_item {
      display flex
      flex-direction column
      gap 12px
      align-items center
      padding 32px 40px
      +below(768px) {
        width 100%
        padding 16px 40px
        padding-bottom 0
      }
    }
  }
}

.card {
  margin-bottom 2px
  border: 1px solid divider;
  border-radius: 16px;
  overflow hidden
  height: 448px;
  width 100%
  display flex
  +below(768px) {
    flex-direction column
    height auto
  }

  &__img {
    width 100%
    height 100%
    object-fit cover

    &-container {
      height 100%
      flex 1
    }
  }

  &__content {
    width 331px
    padding: 63px;
    display flex
    flex-direction column
    gap 40px
    +below(768px) {
        padding 24px
        width 100%
    }

    &_header {
      display flex
      flex-direction column
      gap 16px
    }

    &_info {
      display flex
      flex-direction column
      gap 16px

      &_item {
        display flex
        align-items center
        gap 8px
        max-width 186px

        &_img {
          max-height 40px
          min-width 40px
        }
      }
    }
  }
}
