
.banners {
  width 100%
  margin-bottom 40px
  +below(768px) {
    margin-bottom 23px
  }

  .splide {
    &__pagination {
      bottom 40px
      +below(768px) {
        bottom 21px
      }
    }
  }

  &__card_mobile {
    width 100%
    border: 1px solid divider;
    border-radius: 16px;
    overflow hidden
    display flex
    flex-direction column
    justify-content center
    height 100%
    +above(769px) {
      display none
    }
    +below(768px) {
      + .catalog__card_img {
        display none
      }
    }
    &_img {
      width 100%
      height 100%
      object-fit cover
      &-container {
        width 100%
        height 224px
        min-height 224px
        object-fit cover
      }
    }
    &_content {
      display flex
      padding 16px 10px 52px 10px
      flex-wrap: wrap;
      justify-content: center
      align-items center
      gap 16px
      height 100%
      &_header {
        display flex
        flex-direction column
        align-items center
        gap 4px
        width: 290px;
      }
    }
  }
}

.stocks-main {
  margin-bottom 64px
  display flex
  flex-wrap wrap
  gap 16px
  +above(769px) {
    display grid
    grid-template-columns repeat(4, 1fr)
  }
  +below(1100px) {
    grid-template-columns repeat(2, 1fr)
    img {
      width 100%
      object-fit cover
    }
  }
  +below(768px) {
    margin-bottom 32px
  }

  &__item {
    border-radius 16px
    overflow hidden
    width 320px
    height 240px
    object-fit cover
    position relative
    +above(769px) {
      width 100%
    }

    &_text {
      position absolute
      top 24px
      left 24px
      max-width 104px
      +below(768px) {
        top: 33px;
        left: 32px;
        max-width 160px;
      }
    }

    &_img {
      width 100%
      height 100%
    }
  }
  +below(768px) {
    flex-wrap nowrap
    overflow auto
    &__item {
      width 264px
      min-width 264px
      height 160px
    }
    img {
      width 100%
      height 100%
      object-fit cover
    }
  }
}

.stages {
  display flex
  flex-direction column
  align-items: center;
  gap 24px
  margin-bottom 72px
  +below(768px) {
    margin-bottom 29px
  }

  &__items {
    display flex
    flex-wrap wrap
    column-gap 16px
    +above(769px) {
      display grid
      grid-template-columns repeat(3, 1fr)
    }
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }
    &.splide {
      +above(769px) {
        display none
      }
    }
    &:not(.splide) {
      +below(768px) {
        display none
      }
    }
  }

  &__item {
    display flex
    flex-direction column
    align-items center
    text-align center
    gap 12px
    padding 32px 40px 16px 40px

    &_img {
      width 56px
      height 56px
    }
    +below(768px) {
      padding 8px 16px 39px 16px
      .text-subtext15 {
        width: 79%;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.feedback-form {
  min-height calc(100% + 54px)
  padding-top 54px
  position relative
  width 100%
  +below(768px) {
    min-height calc(100% + 180px)
    padding-top 205px
    &.is-main {
      min-height none
      padding-top 180px
    }
    &.is-simple {
      padding-top 0
      min-height none
    }
  }

  &__img {
    position absolute
    bottom 0
    z-index: 0;

    &_main {
      right 0
      +below(768px) {
        position absolute
        top -5px
        z-index -1
        left: 50%;
        transform: scale(0.91) translateX(-52%);
      }
    }

    &_catalog {
      left 29.14%
      +below(1010px) {
        left auto
        right 30px
      }
      +below(768px) {
        position absolute
        top -5px
        z-index -1
        left: 50%;
        transform: scale(0.91) translateX(-52%);
      }
    }

    &_delivery {
      left 24.14%
      +below(1010px) {
        left auto
        right 30px
      }
      +below(768px) {
        position absolute
        top -5px
        z-index -1
        left: 50%;
        transform: scale(0.91) translateX(-52%);
      }
    }
  }

  &__content {
    background: cardbg;
    border-radius: 16px;
    overflow: hidden;
    padding 68px 68px 45px 65px
    min-height 224px
    display flex
    flex-wrap wrap
    justify-content space-between
    row-gap: 32px;
    +below(768px) {
      padding 32px
      flex-direction column
      align-items: center;
      gap 32px
    }

    &_main {
      gap 23px
      justify-content: flex-start;
      min-height 240px
      +below(768px) {
        gap 32px
      }
    }
  }

  &__text {
    display flex
    flex-direction column
    gap 12px
    max-width 328px
    z-index: 2;
    +below(768px) {
      gap 17px
      width 100%
      max-width 100%
      align-items: center;
    }
  }

  &__form {
    display flex
    gap 40px
    flex-wrap wrap
    z-index: 2;
    +below(768px) {
      justify-content: center;
      gap 33px
    }

    &_inputs {
      display flex
      flex-direction column
      gap 16px
      max-width 263px
      width 263px
      +below(768px) {
        max-width 100%
        width 100%
      }

      &_catalog {
        width 240px
        +below(768px) {
          width 100%
        }
      }

      &_row {
        display flex
        flex-direction column
        gap 4px
      }
    }

    &_send {
      display flex
      flex-direction column
      gap 11px
      max-width 192px
      +below(768px) {
        gap 14px
        max-width 100%
        width 100%
      }

      & .button {
        width: 184px;
        +below(768px) {
          width 100%
        }
      }
    }
  }
}

.styles {
  margin-top 64px
  margin-bottom 64px
  +below(768px) {
    margin-top 28px
    margin-right -28px
    margin-bottom 24px
  }

  &_factory {
    margin-bottom 0
  }

  &__items {
    display grid
    grid-template-columns repeat(3, 1fr)
    gap 16px
    width: 100%;
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }

    &_block {
      max-width 880px;
      display flex
      flex-wrap wrap
      gap 16px
    }
    &.splide {
      +above(769px) {
        display none
      }
      .splide__pagination {
        display none
      }
      grid-template-columns 1fr
    }
    &:not(.splide) {
      +below(768px) {
        display none
      }
    }
  }

  &__item {
    display: flex
    align-items: flex-end;
    border-radius: 16px;
    padding: 24px;
    overflow: hidden;
    &:not(.styles__item_large) {
      height: 320px;
    }
    position: relative;
    color white;
    transition all 0.3s;
    +below(768px) {
      width 300px !important
      height 320px !important
    }

    &:hover {
      box-shadow: -1px 5px 12px 0 rgba(0, 0, 0, 0.1), -5px 22px 22px 0 rgba(0, 0, 0, 0.09), -11px 49px 30px 0 rgba(0, 0, 0, 0.05), -19px 87px 36px 0 rgba(0, 0, 0, 0.01), -30px 137px 39px 0 rgba(0, 0, 0, 0);

      & .styles__item_desc_circle {
        transform: translateX(10%);
      }
    }

    &_large {
      // two row
      height: 656px;
      grid-row: span 2;
      +below(768px) {
        height 320px
      }
    }

    &_img {
      width: 100%;
      height: 100%;
      position: absolute;
      top 0
      left 0
      z-index -1
    }

    &_desc {
      width: 100%;
      display: flex;
      align-items center
      justify-content: space-between;

      &_circle {
        transition all 0.3s;
        background: white;
        border-radius: 50%;
        padding: 8px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content center;
      }
    }
  }
}

.factory-main {
  display flex
  border-radius 16px
  overflow hidden
  height: 480px;
  background darkred10
  margin-bottom: 12px;
  +below(768px) {
    flex-direction column
    height auto
  }

  &__banner {
    flex 1
    height: 100%;
    +below(768px) {
        width 100%
    }
    .splide {
      &__pagination {
        bottom 40px
        +below(768px) {
          bottom 21px
        }
      }
    }
    img {
      object-fit cover
      +below(768px) {
        height 240px
      }
    }
  }

  &__info {
    height 100%
    display flex
    flex-direction column
    justify-content: center;
    gap 32px
    padding 44px 44px 44px 83px
    width 440px
    min-width 440px
    +below(960px) {
      padding 44px
      min-width 360px
      width 360px
    }
    +below(768px) {
      width 100%
      min-width 100%
      padding 24px 32px
      gap 24px
      .button.button-dark {
        width 100%
        background white
        border 1px solid darkred
        color darkred
      }
    }
    &_content {
      display flex
      flex-direction column
      gap 16px
      max-width 320px
      +below(768px) {
        width 100%
        min-width 100%
      }
    }

    & .button {
      width: 184px;
    }
  }
}
