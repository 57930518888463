.header {
  padding 18px 0
  display flex
  align-items center
  justify-content flex-start
  position sticky
  top 0
  z-index 1100
  background white
  --menu-spacing: 52px
  +below(1300px) {
    --menu-spacing: 36px
  }
  +below(768px) {
    width 100%
    padding 16px 20px
    border-bottom: 1px solid divider;
    //margin-left -20px
    //margin-right -20px
    margin-bottom 16px
  }

  &__container {
    display flex
    align-items center
    justify-content flex-start
  }

  &__logo {
    width 216.7px
    height 40px
    margin-right auto
    +below(768px) {
      width 173.36px
      height 32px
    }

    & img {
      +below(768px) {
        width 173.36px
        height 32px
      }
    }
  }

  &__menu {
    display flex
    align-items center
    gap 24px
    +below(1260px) {
      display none
    }

    & a {
      transition all 0.3s

      &:hover {
        color darkred
      }
    }

    & .header__menu_item_submenu {
      &:first-child {
        margin-left -10%
      }

      &:last-child {
        margin-right -10%
      }
    }

    &_item {
      display flex
      align-items center
      gap 7px
      transition all 0.3s
      position relative

      & img {
        transition all 0.3s
      }

      &:hover {
        img {
          transform rotate(90deg)
        }

        .header__menu_item_submenu {
          opacity 1
          pointer-events all
        }
      }

      &_catalog {
        & .header__menu_item_submenu {
          left -10%
        }
      }

      &_payment {
        & .header__menu_item_submenu {
          left -34%
        }
      }

      &_submenu {
        position absolute
        padding-top 10px
        top 100%
        left 0
        opacity 0
        pointer-events none
        z-index 1
        transition all 0.3s

        &-container {
          overflow hidden
          background white
          border 1px solid divider
          border-radius 8px
          flex-direction column
          display flex

          & a {
            padding 8px 16px
            transition all 0.3s

            &:hover {
              color darkred
              background buttonhover
            }
          }
        }
      }
    }
  }

  &__contacts {
    display flex
    align-items center
    gap 16px
    margin-left var(--menu-spacing)
    +below(768px) {
      display none
    }

    &_social {
      display flex
      align-items center
      gap 16px

      & a {
        width 32px
        height 32px
      }
    }

    &_info {
      display flex
      flex-direction column
      gap 4px
      padding-left 16px
      border-left 1px solid divider
    }
  }

  &__call {
    border-radius 8px
    padding 12px
    width 80px
    height 86px
    background darkred
    display flex
    flex-direction column
    gap 8px
    align-items center
    text-align center
    cursor pointer
    margin-left var(--menu-spacing)
    +below(768px) {
      justify-content space-between
      width 40px
      height 40px
      padding 8px
    }

    & div {
      +below(768px) {
        display none
      }
    }

    & img {
      +below(768px) {
        width 24px
        height 24px
      }
    }
  }

  &__burger {
    border-radius 8px
    width 40px
    height 40px
    padding 8px
    margin-left var(--menu-spacing)
    +above(1260px) {
      display none
    }
    +below(768px) {
      margin-left 8px
    }
    position relative

    .is-close {
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%) scale(0.9)
      opacity 0
      transition all 0.25s
    }
    .is-trigger {
      transition all 0.25s
    }

    &.is-active {
      .is-close {
        transform translate(-50%, -50%) scale(1)
        opacity 1
      }

      .is-trigger {
        opacity 0
        transform scale(1.1)
      }
    }
  }
  &__menu_mobile {
    position fixed
    top 122px
    right 0
    width 375px
    bottom 0
    background white
    padding 29px 22px
    overflow auto
    z-index 10000
    transform translateX(100%)
    transition all 0.25s ease
    opacity 0
    &.is-active {
      transform translateX(0)
      opacity 1
    }
    +below(768px) {
      top 73px
    }
    .header__contacts {
      margin-left 0
      margin-bottom 16px
      justify-content space-between
      +above(768px) {
        display none
      }
      &_info {
        border-left 0
        padding-left 0
      }
      +below(768px) {
        display flex
      }
    }
    &_items {
      display flex
      flex-direction column
      text-align center
      gap 16px
    }
    a {
      display block
      color #393939
      font-size 18px
      line-height  21.09px
      &.is-parent {
        font-weight 700
      }
    }
    .divider {
      margin 8px 0
      height 1px
      background #d7d7d7
      +above(768px) {
        &:first-child {
          display none
        }
      }
    }
  }
}

.feedback-modal {
  border-radius: 16px;
  padding: 64px;
  width: 403px;
  background: cardbg;
  position relative
  display: flex;
  flex-direction column
  align-items center
  gap: 40px;

  &__close {
    position absolute
    top 16px
    right 16px
    cursor pointer
  }

  &__header {
    display flex
    flex-direction column
    gap 16px
    align-items center
    text-align center
  }

  &__send {
    display flex
    flex-direction column
    gap 16px
    width 100%
  }

  &__end {
    border-radius: 16px;
    padding: 64px;
    width: 398px;
    background: cardbg;
    position: relative;
    display: flex;
    flex-direction column
    align-items center
    text-align center
    gap: 16px;

    &_close {
      position absolute
      cursor pointer
      top 16px
      right 16px
    }
  }
  &__create {
    &_img {
      width: 64px;
      height: 64px;
      min-height: 64px;
      border: 5px solid #59001A;
      border-radius: 50%;
      border-right-color: transparent;
    }
  }
}

.loading-img {
  animation-name: loading-img;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes loading-img {
  from {
    transform rotate(0deg);
  }

  to {
    transform rotate(360deg);
  }
}

.call {
  width: 72px;
  height: 72px;
  background: darkred;
  padding 20px
  cursor pointer
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 48px;
  bottom: 48px;
  z-index: 1000;
  +below(768px) {
    width: 44px;
    height: 44px;
    right: 16px;
    bottom: 16px;
  }

  &__img {
    width 30px
    height: 30px
    +below(768px) {
      width: 24px;
      height: 24px;
    }
  }
}
