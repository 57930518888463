.footer {
  padding 48px 20px 96px 20px
  border-top 1px solid divider
  display flex
  flex-wrap wrap
  justify-content space-between
  gap 24px
  +below(768px) {
    flex-direction: column
    gap: 24px
    padding 40px 0px 40px 0px
  }
  &-container {
    display flex
    flex-wrap wrap
    gap 87px
  }
  &__logo {
    width 163.92px
    height 40px
  }
  &__main {
    display flex
    flex-direction column
    justify-content space-between
    max-width 233px
    +below(768px) {
      max-width 100%
    }
    &_text {
      +below(768px) {
        display none
      }
      &_add {
        display none
        max-width 233px
        +below(768px) {
          display block
        }
      }
    }

  }
  &__menu {
    display flex
    gap 40px
    +below(768px) {
      display none
    }
    &_item {
      display flex
      flex-direction column
      gap 12px
      & a {
        transition all 0.3s
        &:hover {
          color darkred
        }
      }
    }
  }
  &__info {
    display flex
    flex-direction column
    justify-content space-between
    align-items flex-end
    margin-left auto
    +below(768px) {
      align-items flex-start
      gap 24px
      margin-left 0
    }
    &_contacts {
      display flex
      gap 40px
      +below(768px) {
          width 100%
          justify-content space-between
        }
      &_info {
        display flex
        flex-direction column
        gap 4px
        & a {
          transition all 0.3s
          &:hover {
            color darkred
          }
        }
      }
      &_social {
        display flex
        flex-direction column
        gap 8px
        +below(768px) {
          flex-direction: row
        }
        & a {
          width 32px
          height 32px
        }
      }
    }
    &_add {
      display flex
      gap 40px
      text-align right
      +below(768px) {
        text-align left
      }
    }
  }
}

.undefined-page {
  margin-top 75px
  margin-bottom 75px
  width 100%
  display flex
  flex-direction column
  align-items center
  justify-content center
  +below(768px) {
    margin-top 16px
    margin-bottom 40px
  }
  &__content {
    display flex
    flex-direction column
    align-items center
    text-align center
    max-width 432px
    +below(768px) {
      max-width 100%
    }
    &_desc {
      gap 24px
      margin-top: -5px;
      +below(768px) {
        margin-top: 0;
      }
      & .button {
        +below(768px) {
          width 100%
        }
      }
    }
    &_text {
      font-weight: 400;
      font-size: 239px;
      line-height: 100%;
      margin-top: -16px;
      +below(768px) {
        font-weight: 400;
        font-size: 120px;
        line-height: 100%;
      }
    }
  }
}
