.contacts {
  border: 1px solid divider;
  border-radius: 16px;
  overflow hidden
  display flex
  justify-content space-between
  position absolute
  width 100%
  top 0
  left 0
  opacity 0
  background-color white
  transition all 0.3s
  z-index -1
  +below(768px) {
    flex-direction: column-reverse;
    border: none;
    border-radius 0
    width calc(100% + 40px)
    margin-left -20px
  }
  &-active {
    opacity 1
    z-index 100
  }
  &-main {
    position relative
    height 386px
    width 100%
    +below(768px) {
      height 869px
    }
  }
  &__content {
    padding 32px
    display flex
    flex-direction column
    justify-content space-between
    &_info {
      display flex
      flex-direction column
      gap 16px
      &_item {
        display flex
        flex-direction column
        gap 4px
      }
    }
    +below(768px) {
      padding 32px 20px
    }
    &_buttons {
      display flex
      align-items center
      gap 8px
      +below(768px) {
        margin-top 24px
        flex-wrap wrap
      }
      &_call {
        display flex
        align-items center
        gap 12px
        cursor pointer
        &_img  {
          width 24px
          height 24px
        }
      }
    }
  }
  &__map {
    width 880px
    height 386px
    +below(768px) {
      width 100%
      height 560px
    }
    &_container {
      width 100%
      height 100%
    }
  }
}

.yamap__img {
  margin-top: -66px;
  margin-left: -24px;
}
