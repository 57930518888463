.reviews {
  display grid
  grid-template-columns repeat(3, 1fr)
  column-gap 16px
  row-gap 22px
  margin-bottom: 6px;
  width 100%
  +below(1200px) {
    grid-template-columns repeat(2, 1fr)
  }
  +below(768px) {
    grid-template-columns repeat(1, 1fr)
  }
  &__item {
    display flex
    flex-direction column
    gap 8px
    +below(768px) {
      width: 100%
    }
    &_img {
      width 100%
      height 100%
      object-fit cover
      &-container {
        width 100%
        height: 289px;
        border-radius: 16px;
        overflow: hidden;
        +below(768px) {
          height: 224px;
        }
      }
    }
    &_content {
      padding-left 16px
      display flex
      flex-direction column
    }
  }
}
