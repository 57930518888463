.stocks {
  display grid
  grid-template-columns repeat(2, 1fr)
  +below(991px) {
    grid-template-columns repeat(1, 1fr)
  }
  width 100%
  gap 16px
  --stock-size: 190px
  &__item {
    border-radius: 16px;
    min-height: 240px;
    padding: 34px 32px;
    overflow hidden;
    transition all 0.3s
    cursor pointer;
    position relative;
    background-color none !important
    +below(768px) {
      width: 100%;
      &.is-top {
        padding-top var(--stock-size)
        .stocks__item_img {
          top 0
          bottom 0
        }
      }
      &:not(.is-top) {
        padding-bottom var(--stock-size)
      }
      &.is-gray {
        background-color #F4EDEE
      }
      &.is-orange {
        background-color #FCD4AD
      }
      &.is-blue {
        background-color #EDEDED
      }
      &.is-brown {
        background-color #EBDDD4
      }
    }
    &_img {
      position absolute;
      top 0;
      right 0;
      min-width 100%;
      min-height 100%;
      z-index -1;
      +below(768px) {
        height var(--stock-size)
        min-height auto
        z-index 0;
        object-fit cover
        bottom 0
        top auto
      }
      &.is-mobile {
        +above(768px) {
          display none
        }
        +below(768px) {
          +.stocks__item_img {
            display none
          }
        }
      }
    }
    &_content {
      display flex
      flex-direction column
      justify-content space-between
      max-width 280px
      height 100%
      +below(768px) {
        max-width 100%
        height max-content
        gap 12px
      }
      &_header {
        display flex
        flex-direction column
        gap 12px
      }
      &_footer {
        display flex
        flex-direction column
        gap 10px
      }
    }
  }
}
