@import 'rupture';
@import '@splidejs/splide/css';

* {
  margin 0;
  padding 0;
  box-sizing border-box;

  &::before, &::after {
    box-sizing border-box;
  }
}

//  цвета
darkred = #59001A;
darkred10 = #EFE6E9;
darkred40 = #BD99A3;
white = #FFFFFF;
cardbg = #EEEEEE;
divider = #D7D7D7;
grayred = #A79A9A;
gray = #969696;
red = #969696;
green = #969696;
buttonhover = #F7F3F4;
buttonactive = #EFE6E9;
red = #DB3F3F;
error = #FF0000;
scarlet = #940D18;

//цвета текста
text-white = #FFFFFF;
text15 = #262626;
text22 = #393939;
subtext = #9B9B9B;
text-darkred = #59001A;
text-red = #DB3F3F;
text-error = #FF0000;
text-scarlet = #940D18;
text-black = #000;

body {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 400;
  color: text15;
}

a {
  text-decoration: none !important;
  cursor: pointer;
  color: inherit;
  transition all 0.3s !important;
}

// шрифты
.text-h1 {
  font-family: "Bona Nova SC", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-size: 36px;
  line-height: 100%;
  font-style: normal;
  letter-spacing: 1.5px;
  //text-transform: uppercase;
  +below(768px) {
    font-size: 28px;
    line-height: 129%;
  }
}

.text-h2 {
  font-family: "Bona Nova SC", serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-size: 28px;
  line-height: 129%;
  font-style: normal;
  letter-spacing: 1.5px;
  //text-transform: uppercase;
  +below(768px) {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  }
}

.text-h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 130%;
  +below(768px) {
    font-size: 18px;
    line-height: 122%;
  }
}

.text-cardhead {
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  color: text22;
  +below(768px) {
    font-size: 18px;
    line-height: 122%;
  }
}

.text-cardprice {
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: text22;
  +below(768px) {
    font-size: 18px;
    line-height: 122%;
  }
}

.text-h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 112%;
  +below(768px) {
    font-size: 15px;
    line-height: 160%;
  }
}

.text-h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 133%;
}

.text-sale {
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;
}

.text-sale-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 156%;
}

.text-subtext15 {
  font-weight: 400;
  font-size: 15px;
  line-height: 133%;
}

.text-subtext {
  font-weight: 400;
  font-size: 12px;
  line-height: 133%;
}

.text-cardoldprice {
  font-weight: 400;
  font-size: 15px;
  line-height: 133%;
  text-decoration: line-through;
  color: red;
}

.text-subitem {
  font-weight: 400;
  font-size: 15px;
  line-height: 120%;
  font-variant: uppercase;
}

.text-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
}

.text-semibold {
  font-weight: 500;
  font-size: 14px;
  line-height: 114%;
  text-transform: uppercase;
}

.text-regular {
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
}

.text-subtext12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  +below(768px) {
    line-height: 15px;
  }
}

.text-cardsubprice {
  font-weight: 400;
  font-size: 12px;
  line-height: 117%;
  color: subtext;
}

.text-discount {
  font-weight: 600;
  font-size: 12px;
  line-height: 167%;
  color: white;
}

.text-subtextsmall {
  font-weight: 500;
  font-size: 11px;
  line-height: 136%;
  text-transform: uppercase;
}

//html {
//  font-size clamp(12px, 1.5vw, 16px);
//}
//
//rem(px) {
//  return px / 16 + 'rem';
//}

.text-color-white {
  color: text-white;
}

.text-color-black {
  color: text-black;
}

.text-color-text22 {
  color: text22;
}

.text-color-text15 {
  color: text15;
}

.text-color-darkred {
  color: text-darkred;
}

.text-color-scarlet {
  color: text-scarlet;
}

.text-color-subtext {
  color: subtext;
}

.text-color-red {
  color: text-red;
}

.text-color-error {
  color: text-error;
}

//
//.text-color-green {
//  color: text-green;
//
//  &:hover {
//    color: text-dark-green;
//  }
//}

.container {
  margin: 0 auto;
  width: 1328px;
  max-width 100%
  padding 0 16px
  +below(1248px) {
    width 100%
    padding 0 40px
  }
  +below(768px) {
    width 100%
    padding 0 20px
    overflow hidden
  }
}

button, .button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  transition: all 0.3s;
}

.button-dark {
  background: darkred;
  border: none;
  color: white;
}

.button-light {
  background: white;
  border: 1px solid text22;
  color: text22;
  font-weight: 400;
  font-size: 15px;
  line-height: 133%;

  &:hover {
    background: buttonhover;
  }

  &:active {
    background: buttonactive;
    border: 1px solid darkred;
    color: darkred;
  }

  &-active {
    background: buttonactive;
    border: 1px solid darkred;
    color: darkred;
  }

  &-red {
    background: white;
    border: 1px solid darkred;
    color: darkred;
  }
}


input, .input {
  appearance none
  display: inline-block;
  border: 1px solid grayred;
  border-radius: 8px;
  padding: 7px 16px;
  cursor: pointer;
  background: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 137%;
  color: text22;
  transition: all 0.3s;

  &::placeholder {
    color: gray;
  }

  &:hover {
    border: 1px solid gray;
  }

  &:focus {
    outline: none;
    border: 1px solid darkred;
  }
  +below(768px) {
    padding-top 8px
    padding-bottom 8px
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// input file
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;
}

.select {
  border: 1px solid disable;
  border-radius: 100px;
  background white;
  padding: 8px 36px 8px 12px;
  display flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  text-align: center;
  width 80px
  height 40px
  outline none
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  //background-image: url("../images/icons/select_arrow.png")
  background-repeat: no-repeat;
  background-position: top 8px right 12px;
  transition all 0.3s;
}

.errors-container {
  &:empty {
    display: none;
  }

  & div:not(:first-child) {
    display: none;
  }
}

.is-label-invalid {
  color: text-error !important;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
}

.is-input-invalid {
  border: 1px solid error !important;

  &-password {
    border: 1px solid error !important;
  }
}

.hidden {
  display: none !important;
}

.hidden-opacity {
  opacity: 0;
  pointer-events: none;
}

.content {
  padding-bottom 48px
  min-height: 51vh;
  +below(768px) {
    padding-bottom 40px
    min-height: 20vh;
  }

  &__container {
    display flex
    flex-direction column
    align-items center
    gap 40px
    +below(768px) {
      margin-top 16px
      gap 24px
    }
  }
}

.splide {
  width 100%
  height 100%

  &__track {
    width 100%
    height 100%
  }
}

//кнопки пагинации
.splide__pagination {
  display flex
  gap 8px

  &__page {
    background: white;
    border-radius: 8px;
    width: 24px;
    height: 4px;
    opacity 1
    margin 0 0
    +below(768px) {
      background #EDEDED
    }

    &.is-active {
      background: darkred;
      transform: scale(1);
    }
  }
}

.splide__arrow {
  background: darkred;
  height: 40px;
  width: 40px;
  opacity: 1;

  &--prev {
    left 40px
  }

  &--next {
    right 40px
  }
}

/* Стили для бэкдропа модального окна */
dialog .dialog-backdrop {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1101;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s;
}

/* Стили для бэкдропа модального окна, когда тег <dialog> имеет атрибут open */
dialog[open] .dialog-backdrop {
  opacity: 1;
}

/* Стили для контентной части модального окна */
dialog {
  display: block;
  background: none;
  border: none;
  visibility: hidden;
  transition visibility .3s ease

  &[open] {
    visibility: visible;
  }

  &::backdrop {
    display: none;
  }
}

.dialog-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left 0
  padding-top 24px
  padding-bottom 24px
  +below(768px) {
    padding-top 20px
    padding-bottom 20px
  }

  & > div:first-child {
    margin auto
  }
}

.transition-fade {
  transition .3s ease
  opacity 0
  transform scale(0.8)

  [open] & {
    opacity 1
    transform scale(1)
  }
}

.transition-fade-right {
  transition .3s ease
  opacity 0
  transform translateX(100%)

  [open] & {
    opacity 1
    transform translateX(0)
  }
}

.dialog-content[data-id=header-modal] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top 0
  right: 0;
}

/* Стили для контентной части модального окна, когда тег <dialog> имеет атрибут open */
dialog[open] .dialog-content {
  opacity: 1;
  top 0
  right: 0;
  z-index 1101
  overflow auto
}

dialog[open] .product__dialog-content, dialog[open] .product__size_dialog-content {
  overflow auto
  z-index 1105
}

@import "nice-select2.css";
@import 'header.styl';
@import 'footer.styl';
@import 'main.styl';
@import 'payment.styl';
@import 'stocks.styl';
@import 'catalog.styl';
@import 'product.styl';
@import 'care-centre.styl';
@import 'delivery.styl';
@import 'reviews.styl';
@import 'factory.styl';
@import 'contacts.styl';


.is-scroll-lock {
  overflow: hidden;
}
