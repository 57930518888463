.catalog {
  display flex
  flex-direction column
  align-items: center;
  gap 32px
  width 100%
  +below(768px) {
    gap 24px
  }
  & button {
    +below(768px) {
      width 100%
    }
  }
  &__buttons {
    display flex
    justify-content: center;
    gap 8px
    width: 100%;
    overflow: auto;
    +below(768px) {
      justify-content: flex-start;
    }
    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }
    & {
      -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
    }
  }
  &__cards {
    display flex
    flex-wrap wrap
    gap 16px
    width 100%
    +above(768px) {
      display grid
      grid-template-columns repeat(3, 1fr)
    }
    +below(1100px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(768px) {
      flex-direction column
    }
  }
  &__card {
    border-radius: 16px;
    overflow hidden
    min-height: 460px;
    background: white;
    transition all 0.3s
    display flex
    flex-direction column
    +below(768px) {
      width: 100%;
      min-height: 413px;
    }
    &_mini {
      min-height: 406px;
      +below(768px) {
        min-height: 357px;
      }
      & .catalog__card_content_price {
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
      .button-order {
        width auto
      }
    }
    &:hover {
      box-shadow: -1px 5px 12px 0 rgba(0, 0, 0, 0.1), -5px 22px 22px 0 rgba(0, 0, 0, 0.09), -11px 49px 30px 0 rgba(0, 0, 0, 0.05), -19px 87px 36px 0 rgba(0, 0, 0, 0.01), -30px 137px 39px 0 rgba(0, 0, 0, 0);
    }

    &_img {
      width: 100%;
      height 100%
      object-fit cover
      &-container {
        width 100%
        height 288px
        object-fit cover
        +below(768px) {
          height 240px
        }
      }
    }
    &_content {
      display flex
      flex-direction column
      align-items: flex-start;
      gap 10px
      padding: 16px 24px 24px 23px;
      border-bottom: 1px solid divider;
      border-left: 1px solid divider;
      border-right: 1px solid divider;
      border-radius: 0 0 16px 16px;
      &_price {
        display flex
        gap 16px
        padding-bottom 6px
        &_block {
          display flex
          flex-direction column
          gap 4px
        }
      }
      &_mini {
        padding: 16px 24px 18px 23px;
      }
      & button {
        +below(768px) {
          width 100%
        }
      }
    }
  }
  &__infoblock {
    border-radius: 16px;
    overflow hidden
    min-height: 460px;
    background: cardbg;
    transition all 0.3s
    padding 40px
    position relative
    display: flex;
    align-items flex-start
    &_reverse {
      align-items flex-end
    }
    +below(768px) {
      width 100%
    }
    &_text {
      display flex
      justify-content center
      flex-direction column
      gap 8px
      position relative
      z-index 1
    }
    &_img {
      width 100%
      height 100%
      object-fit cover
      &-container {
        position absolute
        left 0
        top 0
        z-index 0
        width: 100%;
        height: 100%;
      }
    }
  }
  & .splide__arrows {
    display none
  }
}

.materials {
  margin-top 64px
  margin-bottom 46px
  display grid
  gap 17px
  grid-template-columns repeat(3, 1fr)
  +below(1100px) {
    grid-template-columns repeat(2, 1fr)
  }
  +below(768px) {
      grid-template-columns repeat(1, 1fr)
  }
  &__item {
    border-radius: 16px;
    min-height: 180px;
    position relative;
    overflow hidden
    padding 32px
    +below(768px) {
      min-height: 142px;
    }
    &_1 {
      background-color: #EEE2E5;
    }
    &_3 {
      background-color: #99B5B4;
    }
    &_2 {
      background-color: #DCDBE0;
    }
    &_img {
        width: 100%;
        height: 100%;
        object-fit cover
      +below(768px) {
        display none
      }
      &_mobile {
        width 100%
        height 100%
        object-fit cover
        display none
        +below(768px) {
          display block
        }
      }
      &-container {
        position absolute;
        top 0
        right: -26px;
        z-index 0
        width: 100%;
        height: 100%;
      }

    }
    &_text {
      display flex
      flex-direction column
      gap 12px
      max-width 200px
      position: relative;
      z-index 1
    }
  }
}
