.delivery {
  display: flex;
  flex-direction column
  gap 24px
  &__main {
    display: flex
    flex-direction column
    gap 24px
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    background: #e4ebee;
    padding: 64px;
    +below(768px) {
      padding: 32px;
      align-items: center;
    }
    &_img {
      position absolute
      top -56px
      right -193px
      width: 774px;
      height: 516px;
      z-index 0
      +below(1200px) {
        display none
      }
    }
    &_cities {
      display flex
      gap 55px
      padding-bottom 24px
      border-bottom 1px solid divider
      max-width 627px
      position relative
      z-index 1
      +below(768px) {
        flex-direction column
        align-items center
        width: 100%;
        max-width 100%
        gap 24px
      }
      &_item {
        display flex
        max-width 286px
        flex-direction column
        align-items: flex-start;
        gap 16px
        &_content {
          display flex
          flex-direction column
          gap 12px
        }
      }
    }
    &_info {
      display flex
      flex-direction column
      gap 4px
      position relative
      z-index 1
    }
  }
  &__guarantee {
    display: flex
    margin-top 14px
    border: 1px solid divider;
    border-radius: 16px;
    min-height: 392px;
    height: 392px;
    overflow: hidden;
    +below(768px) {
      flex-direction column
      min-height: auto;
      height: max-content;
    }
    &_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align top
      &-container {
        flex 1
        height 100%

        +below(768px) {
          min-width 100%
          width 100%
          height 240px
        }
      }
    }
    &_content {
      padding: 64px;
      display: flex;
      flex-direction column
      justify-content: center;
      gap 16px
      width 445px
      +below(768px) {
        padding: 24px 32px 32px 32px;
        background: cardbg;
        width 100%
      }
    }
  }
  & .sale__content_item_img {
    width: 56px;
    height 56px
  }
}
