.care-centre {
  max-width 267px
  width 267px
  display flex
  flex-direction column
  gap 27px
  +below(500px) {
    max-width 100%
    width 100%
  }
  &__header {
    display flex
    flex-direction column
    align-items: center;
    text-align center
    max-width: 656px;
    margin: 0 auto;
    gap 32px
    +below(768px) {
      max-width 100%
      width 100%
      gap 16px
    }
  }
  &__inputs {
    display flex
    flex-direction column
    text-align center
    gap 17px
    &_row {
      width 100%
      display flex
      gap 15px
      &_left {
        width 92px
        min-width 92px
      }
      &_right {
        width 100%
      }
    }
  }
  &__send {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 12px;
  }
  &__textarea {
    height: 96px;
    resize none
  }
  &__file {
    width: 100%;
    height: 40px;
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &-container {
      display flex
      flex-direction column
      align-items center
      gap 5px
      position relative
    }
    &_text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 183px;
      padding-left 36px
    }
    &_img {
      position: absolute;
      z-index: 1;
      top: 8px;
      left: 64px;
      cursor: pointer;
    }
    &_block {
      display: flex;
      flex-direction column
      margin-bottom 19px
      width: 100%;
      &:empty {
        display: none;
      }
    }
    &-element {
      display: flex;
      padding 12px 24px 11px 0
      border-bottom: 1px solid divider
      gap 12px
      position relative
      align-items: center;
      &_close {
        position absolute;
        top 12px
        right 0
        cursor pointer
        z-index 1
      }
    }
  }
}